require("vanilla-cookieconsent")
import * as CookieConsent from 'vanilla-cookieconsent';
export enum tpgCookieConsentCategory {
    none = 1,
    necessary = 1 << 0,
    analytics = 2 << 1,
    targeting = 3 << 2
}
window.addEventListener('load', function () {

    const config = (window as any).cookieConsentConfig;

    if (!config)
        return;

    const tr = config.translations;
    const cookieConsent = initCookieConsent();

    function toTpgCookieConsentCategory(cookie: SavedCookieContent) {
        let cValue = tpgCookieConsentCategory.none;
        if (cookie.categories.includes("necessary"))
            cValue = cValue | tpgCookieConsentCategory.necessary;
        if (cookie.categories.includes("analytics"))
            cValue = cValue | tpgCookieConsentCategory.analytics;
        if (cookie.categories.includes("targeting"))
            cValue = cValue | tpgCookieConsentCategory.targeting;
        return cValue;
    }

    const acceptCookie = async function (value: tpgCookieConsentCategory) {
        var form = new FormData();
        form.append('cookie_setting', value as any);

        await fetch("/FmCookies/SaveCookieValue", {
            method: 'post',
            body: form
        });
    };

    const cookieConsentConfig: UserConfig = {
        gui_options: {
            consent_modal: {
                layout: config.layout,
                transition: 'slide',
                position: 'middle center'
            },
            settings_modal: {
                layout: 'box',
                transition: 'slide'
            }
        },
        current_lang: 'en',
        autoclear_cookies: false,
        use_rfc_cookie: true,
        mode: config.mode,
        force_consent: config.force_consent,
        delay: 1500, //Number of milliseconds before showing the consent-modal
        hide_from_bots: true,
        page_scripts: true,
        autorun: false,
        revision: 0,
        onFirstAction: function (userPreferences: UserPreferences, cookie: SavedCookieContent) {
            const category = toTpgCookieConsentCategory(cookie);
            acceptCookie(category);
        },
        onChange: function (cookie: SavedCookieContent, changedCookieCategories: string[]) {
            const category = toTpgCookieConsentCategory(cookie);
            acceptCookie(category);
        },
        languages: {
            'en': {
                consent_modal: {
                    title: tr.cookie.title,
                    description: tr.cookie.description,
                    primary_btn: {
                        text: tr.cookie.button.accept_all,
                        role: 'accept_all'
                    },
                    secondary_btn: {
                        text: tr.cookie.button.settings,
                        role: 'settings'
                    }
                },
                settings_modal: {
                    title: tr.cookie.preferences.title,
                    save_settings_btn: tr.cookie.button.customize,
                    accept_all_btn: tr.cookie.button.accept_all,
                    
                    reject_all_btn: tr.cookie.button.reject_all,
                    close_btn_label: tr.Close,
                    cookie_table_headers: [
                        {col1: tr.Name},
                        {col2: tr.Domain},
                        {col3: tr.Expiration},
                        {col4: tr.Description}
                    ],
                    blocks: [
                        {
                            title: tr.cookie.preferences.subtitle,
                            description: tr.cookie.preferences.description,
                        }, {
                            title: tr.cookie.preferences.necessary.title,
                            description: tr.cookie.preferences.necessary.description,
                            toggle: {
                                value: 'necessary',
                                enabled: true,
                                readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
                            }
                        }, {
                            title: tr.cookie.preferences.analytics.title,
                            description: tr.cookie.preferences.analytics.description,
                            toggle: {
                                value: 'analytics',     // your cookie category
                                enabled: true,
                                readonly: false
                            },
                            cookie_table: [             // list of all expected cookies
                                {
                                    col1: '^_ga',       // match all cookies starting with "_ga"
                                    col2: 'google.com',
                                    col3: '2 years',
                                    col4: '',
                                    is_regex: true
                                },
                                {
                                    col1: '_gid',
                                    col2: 'google.com',
                                    col3: '1 day',
                                    col4: '',
                                }
                            ]
                        }
                        /*
                        {
                            title: tr.cookie.preferences.targeting.title,
                            description: tr.cookie.preferences.targeting.description,
                            toggle: {
                                value: 'targeting',
                                enabled: true,
                                readonly: false
                            }
                        }
                         */
                    ]
                }
            }
        }
    };

    cookieConsent.run(cookieConsentConfig);

    (window as any).isCookieConsentSet = () => {
        return cookieConsent.validCookie('cc_cookie');
    };
    
    window.addEventListener('click', function (e) {
        if(!(window as any).isCookieConsentSet()) {
            cookieConsent.show();
            e.preventDefault();
            e.stopImmediatePropagation();
            e.stopPropagation();
        }
    })
});

